import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "../../config/config.service";
import { OfferBundleCheckoutRequirement } from "../models/offer.model";

@Injectable({
    providedIn: 'root'
})
export class OfferBundleCheckoutRequirementService {
    protected readonly baseUrl: string;

    constructor(protected http: HttpClient, protected configService: ConfigService) {
        this.baseUrl = `${this.configService.environment.applications.url}/api/offer-bundle`;
    }

    getList(offerBundleId: number): Observable<OfferBundleCheckoutRequirement[]> {
        return this.http.get<OfferBundleCheckoutRequirement[]>(`${this.baseUrl}/${offerBundleId}/checkout-requirements`);
    }
}
