<div id="sidenav-loc-law-card-chart" fxLayout="column" fxLayoutAlign="center center">
  <div fxLayout="column" fxLayoutAlign="center center" *ngIf="loan.status === loanStatus.Repayment && !casePaymentRequired">
    <span class="title white">{{loan?.loanInfo?.principalBalance | currency:'$'}}</span>
    <div fxLayout="row" fxLayoutAlign="center center" class="mat-title">
      <span>Balance</span>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" *ngIf="loan.status === loanStatus.Repayment && casePaymentRequired">
    <span *ngIf="casePaymentWithinDays > 0">
      This case has been moved to case resolution. The case loan balance of ${{loan?.loanInfo?.principalBalance}} must be
      paid in full within {{casePaymentWithinDays}} day(s), {{loan?.fullRepaymentDueDate | date: 'mediumDate'}}
    </span>
    <span *ngIf="casePaymentWithinDays <= 0">
      Full payment is past due as of {{loan?.fullRepaymentDueDate | date: 'mediumDate'}}.
      The case loan balance of ${{loan?.loanInfo?.principalBalance}} must be paid in full to avoid penalties
    </span>
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" *ngIf="loan.status !== loanStatus.Repayment">
    <span class="title white">{{loan?.loanInfo?.availableFunds | currency:'$'}}</span>
    <div fxLayout="row" fxLayoutAlign="center center" class="mat-title">
      <span>Available</span>
      <mat-icon *ngIf="loan?.productCode === productCodes.Law" (click)="showAvailableFundsDialog()">info</mat-icon>
    </div>
  </div>
  <mat-progress-bar [ngClass]="{'loc-chart': isLocLoan}" mode="determinate" value="{{utilization}}" *ngIf="!casePaymentRequired"></mat-progress-bar>
  <div fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="24px" class="layout padding-top">
    <button mat-raised-button [ngClass]="getButtonColorClass(drawEnabled)"  (click)="transfer('draw')" [disabled]="!drawEnabled"
      *ngIf="!hasAvailableRenewalToSign && drawEnabled" [fxHide.lt-sm]="showAvailableFunds"
      [fxHide.lt-md]="widgetLayout" name="draw">
      <span class="avatar medium action-icon {{brandingData.colorSchema}}">D</span>
      <span>DRAW</span>
    </button>
    <button mat-raised-button [ngClass]="getButtonColorClass(payEnabled)" (click)="transfer('payment')" *ngIf="payEnabled && !resolveCaseEnabled"
      [fxHide.lt-sm]="showAvailableFunds" [fxHide.lt-md]="widgetLayout">
      <span class="avatar medium action-icon {{brandingData.colorSchema}}">P</span>
      <span>PAY</span>
    </button>
    <button mat-raised-button [ngClass]="getButtonColorClass(resolveCaseEnabled)" (click)="resolveCase()" *ngIf="resolveCaseEnabled"
    [fxHide.lt-sm]="showAvailableFunds" [fxHide.lt-md]="widgetLayout">
    <span class="avatar medium action-icon {{brandingData.colorSchema}}">P</span>
    <span>PAYOFF</span>
  </button>
  </div>
</div>
