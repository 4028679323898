import { formatCurrency } from "@angular/common";
import { Component, OnInit, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AvailableFundsExplanationDialogComponent, BrandingService, CaseResolutionDialogComponent, CaseResolutionDialogData, CompanyBranding, LiquidityEvent, MessageService, ProductCode } from "common";
import { LocLawChartContentComponent } from "../loc-law-card-chart.component";
import { AccountLiquidityEventAddComponent } from "../../../account/account-liquidity-event-add/account-liquidity-event-add.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { catchError, switchMap, take, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { LoanFacade } from "../../../loan/domain/+state/loan.facade";
import { LiquidityEventFacade } from "../../../liquidity-event/domain/+state/liquidity-event.facade";

@UntilDestroy()
@Component({
  selector: "ifp-sidenav-loc-law-card-chart",
  templateUrl: "./sidenav-loc-law-card-chart.component.html",
  styleUrls: ["./sidenav-loc-law-card-chart.component.scss"],
})
export class SidenavLocLawChartContentComponent
  extends LocLawChartContentComponent
  implements OnInit {
  productCodes = ProductCode;
  liquidityEvent: LiquidityEvent;
  brandingData: CompanyBranding;
  private liquidityEventCache: { [loanId: number]: LiquidityEvent } = {};
  private isLiquidityEventFetchingInProgress: boolean = false;

  constructor(
    router: Router,
    private dialog: MatDialog,
    private messageService: MessageService,
    private loanFacade: LoanFacade,
    private liquidityEventFacade: LiquidityEventFacade,
    private brandingService: BrandingService
  ) {
    super(router);
  }

  ngOnInit(): void {
    this.fetchAndSetLiquidityEvent(() => super.ngOnInit());
    this.initBranding();
  }

  private initBranding() {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding) => {
        this.brandingData = branding;
      });
  }

  override ngOnChanges(changes: SimpleChanges): void {
    this.fetchAndSetLiquidityEvent(() => super.ngOnChanges(changes));
  }

  private fetchAndSetLiquidityEvent(callback: () => void): void {

    const cachedEvent = this.liquidityEventCache[this.loan.id];
    if (cachedEvent !== undefined) {
      this.setLiquidityEvent(cachedEvent);
      callback();
    }
    else if (!this.isLiquidityEventFetchingInProgress) {
      this.isLiquidityEventFetchingInProgress = true;
      this.loanFacade
        .getLiquidityEventForLoan(this.loan.id)
        .pipe(untilDestroyed(this))
        .subscribe((event) => {
          this.setLiquidityEvent(event);
          this.liquidityEventCache[this.loan.id] = event;
          callback();
          this.isLiquidityEventFetchingInProgress = false;
        });
    }
  }

  private formatUSDCurrency(value?: number) {
    return formatCurrency(value, "en-US", "$");
  }

  showAvailableFundsDialog(): void {
    AvailableFundsExplanationDialogComponent.show(
      this.dialog,
      `Your available funds is the lower amount of either the credit limit (${this.formatUSDCurrency(
        this.loan?.loanInfo?.creditLimit
      )}) assigned to ${this.loan?.description}
      minus the outstanding balance (${this.formatUSDCurrency(
        this.loan?.loanInfo?.principalBalance
      )}), or the Master Service Agreement limit (${this.formatUSDCurrency(
        this.loan?.loanInfo?.msaCreditLimit
      )})
      minus the aggregate outstanding balances of all your cases (${this.formatUSDCurrency(
        this.loan?.loanInfo?.entityPrincipalBalance
      )}).`,
      this.loan?.loanInfo?.availableFunds,
      this.loan?.loanInfo?.msaCreditLimit
    );
  }

  resolveCase() {
    AccountLiquidityEventAddComponent.show(
      this.dialog,
      this.liquidityEvent?.litigationId
    )
      .pipe(
        untilDestroyed(this),
        switchMap((data) => {
          if (data) {
            return this.loanFacade.saveLiquidityEvent(
              this.liquidityEvent?.litigationId,
              this.loan?.id,
              data.resolutionStatus,
              data.resolutionDate,
              data.note,
              data.isConsented
            );
          }
          return EMPTY;
        }),
        catchError((error) => this.submitError(error)),
        switchMap(() =>
          this.loanFacade.getLiquidityEventForLoan(this.loan?.id)
        ),
        tap((event: LiquidityEvent) => {
          this.liquidityEventCache[this.loan.id] = event;
          this.setLiquidityEvent(event);
        }),
        switchMap(() => this.liquidityEventFacade.refreshLitigation(this.loan?.id)),
        switchMap(() => this.loanFacade.refreshLoan(this.loan?.id)),
        switchMap(() => this.loanFacade.getCasePaymentDueDetails(this.loan?.id)),
        take(1),
        switchMap((data: CaseResolutionDialogData) => CaseResolutionDialogComponent.show(this.dialog,
          {
            ...data,
            trustpilotReviewUrl: this.brandingData.trustpilotReviewUrl, googleReviewUrl: this.brandingData.googleReviewUrl
          }))
      )
      .subscribe();
  }

  setLiquidityEvent(event: LiquidityEvent) {
    this.liquidityEvent = event;
    this.resolveCaseEnabled = event ? !event.resolutionStatus : false;
  }

  getButtonColorClass(value: boolean) {
    if (value) return this.brandingData.colorSchema + "-color";
    else return null;
  }

  submitError(error: any) {
    this.messageService.error(error);
    return EMPTY;
  }
}
