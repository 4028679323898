import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CaseFinancingLoanData,
  LoanData,
  LoanDataSummary,
} from "../../loan/domain/models/loan.model";
import { Subscription } from "rxjs";
import {
  FilterParameter,
  LoanStatus,
  ProductCode,
  AccountFilters,
  AccountQueryParams,
  EntityExData,
} from "common";
import { MatDialog } from "@angular/material/dialog";
import { LoanFilterService } from "../../loan/infrastructure/loan-filter.service";
import {
  CustomerData,
  KBAVerificationStatus,
} from "../../customer/domain/models/customer.model";
import * as _ from "lodash";
import { AccountListFilterDialogComponent } from "./account-list-filter-dialog/account-list-filter-dialog.component";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { AccountSearchInputService } from "../account-search-input/account-search-input.service";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { LoanFacade } from "../../loan/domain/+state/loan.facade";
import { LoanHelper } from "../../loan/infrastructure/loan.helper";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifp-account-list",
  templateUrl: "./account-list.component.html",
  styleUrls: ["./account-list.component.scss"],
})
export class AccountListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  hidePageSize: boolean;
  loans: (LoanData & CaseFinancingLoanData)[];
  filters: AccountFilters;
  loanStatus = LoanStatus;
  customer: CustomerData;
  entity: EntityExData;
  form: UntypedFormGroup;
  showAccountsFilter = false;

  private displayedColumns = [
    { columnName: "favorite", showForProductCode: undefined },
    { columnName: "createdOn", showForProductCode: undefined },
    { columnName: "description", showForProductCode: undefined },
    { columnName: "status", showForProductCode: undefined },
    { columnName: "availableFunds", showForProductCode: undefined },
    { columnName: "creditLimit", showForProductCode: undefined },
    { columnName: "outstandingBalance", showForProductCode: undefined },
    { columnName: "upcomingPayment", showForProductCode: undefined },
    { columnName: "upcomingPaymentDate", showForProductCode: undefined },
    { columnName: "policyNumber", showForProductCode: ProductCode.Law },
    { columnName: "policyLimit", showForProductCode: ProductCode.Law },
    { columnName: "externalId", showForProductCode: ProductCode.Law },
    { columnName: "loanNumber", showForProductCode: undefined },
    { columnName: "more", showForProductCode: undefined },
  ];

  activeFilters: FilterParameter[] = [];
  private subs: Subscription[] = [];
  kbaVerificationStatus = KBAVerificationStatus;
  hoverLoanId: number;
  productCode: ProductCode;
  excludedProductCode: ProductCode;
  productCodes = ProductCode;
  totalResultCount: number;
  hasDrawAccess = (loan: LoanData & CaseFinancingLoanData) => LoanHelper.hasDrawAccess(loan) && !loan.liquidityEventData?.resolutionStatus;
  hasPayAccess = (loan: LoanData) => LoanHelper.hasPayAccess(loan);

  queryParams: AccountQueryParams;

  constructor(
    public loanFacade: LoanFacade,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private loanFilterService: LoanFilterService,
    private formBuilder: UntypedFormBuilder,
    private accountSearchInputService: AccountSearchInputService,
    private queryParamsService: QueryParamsService
  ) {
    this.form = this.formBuilder.group({
      search: "",
    });
  }

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe((res) => {
      this.productCode = res.productCode;
      this.excludedProductCode = res.excludedProductCode;
      this.customer = res.customer;
      this.entity = res.entity;
      this.setLoanData(res.loanSummaryData);
    });

    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((it) => {
      this.queryParams = new AccountQueryParams(
        this.queryParamsService.init(it)
      );
      this.paginator.pageSize = this.queryParams.limit;
      this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
      this.activeFilters = this.loanFilterService.addFilterParameters(
        this.queryParams,
        this.productCode,
        this.excludedProductCode
      );
      this.filters = new AccountFilters(this.queryParams);
      if (!this.filters?.productCode)
        this.filters.productCode = this.productCode;

      if (!this.filters?.excludedProductCode)
        this.filters.excludedProductCode = this.excludedProductCode;
    });
  }

  setHoverLoan(loanId: number) {
    this.hoverLoanId = loanId;
  }

  setPage() {
    this.filter();
  }

  sortData() {
    this.filter();
  }

  filter(): void {
    this.queryParams = this.loanFilterService.filter(
      this.filters,
      this.paginator,
      this.sort
    );
    this.loanFacade
      .querySummary(this.queryParams)
      .pipe(untilDestroyed(this))
      .subscribe((data: LoanDataSummary) => {
        this.setLoanData(data);
        this.activeFilters = this.loanFilterService.addFilterParameters(
          this.queryParams,
          this.productCode,
          this.excludedProductCode
        );
      });
  }

  setLoanData(data: LoanDataSummary) {
    this.totalResultCount = data?.totalCount;
    let loans =
      this.productCode === ProductCode.Law
        ? data?.caseFinancingLoans
        : data?.otherLoans;

    if (!this.queryParams?.sort) {
      loans = [...loans]?.sort((l1, l2) =>
        this.loanFacade.loanSorter(l1, l2, this.customer)
      );
    }

    this.loans = loans as (LoanData & CaseFinancingLoanData)[];
  }

  search(selectedLoan: LoanData) {
    if (selectedLoan) {
      this.filters.description = selectedLoan.description;
      this.filters.loanNumber = selectedLoan.loanNumber;
      this.filter();
    } else {
      const filterParamsToRemove = [
        { id: "description" },
        { id: "loanNumber" },
      ];
      this.activeFilterRemoved(filterParamsToRemove, true);
    }
  }

  download(params: any) {
    const queryParams = Object.assign({}, params);
    queryParams.entityId = this.entity.id;

    this.loanFacade.download(queryParams);
  }

  activeFilterRemoved(
    activefilters: FilterParameter[],
    clearAccountSearch: boolean
  ): void {
    if (!activefilters.length) return;
    activefilters.forEach((item) => {
      this.filters.remove(item.id);
      if (clearAccountSearch) this.accountSearchInputService.clearSearch.emit();
      this.filter();
    });
  }

  draw(loan: LoanData) {
    this.router.navigate([`/account/${loan.id}/transfer/draw`]);
  }

  pay(loan: LoanData) {
    this.router.navigate([`/account/${loan.id}/transfer/payment`]);
  }

  details(loan: LoanData) {
    if (loan) {
      const hasCurrentLoanPending =
        loan.status === LoanStatus.Pending &&
        this.customer?.pendingLoans?.includes(loan?.id);

      if (this.customer.kbaStatus !== KBAVerificationStatus.Success)
        this.router.navigate([`account/${loan.id}/setup`]);
      else if (hasCurrentLoanPending)
        this.router.navigate([`account/${loan.id}/setup/sign-agreement`]);
      else if (this.isNPLLoan(loan))
        this.router.navigate([`/account/${loan.id}/bank-account`]);
      else this.router.navigate([`/account/${loan.id}/transactions`]);
    }
  }

  isNPLLoan(loan: LoanData): boolean {
    return LoanHelper.isNPLLoan(loan?.status);
  }

  isLoanFavorite(loan: LoanData): boolean {
    return _.find(loan?.owners, { customerId: this.customer.id })?.favorite;
  }

  filterDialog() {
    AccountListFilterDialogComponent.show(
      this.dialog,
      this.filters,
      this.productCode
    )
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        if (result) {
          this.filters = result;
          this.paginator.firstPage();
          this.filter();
        }
      });
  }

  updateFavorite(loanId: number, favorite: boolean) {
    this.loanFacade
      .updateFavorite(this.customer.id, loanId, favorite)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.filter();
      });
  }

  closeSearchLoans() {
    this.showAccountsFilter = false;
  }

  getDisplayedColumns(): string[] {
    return this.displayedColumns
      .filter(
        (cd) =>
          (this.productCode && cd.showForProductCode == this.productCode) ||
          cd.showForProductCode == undefined
      )
      .map((cd) => cd.columnName);
  }
}
