import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {
  AppBarActionsComponent,
  AppBarCloseAction,
  AppBarComponent,
  AppBarTitleComponent,
  AppDetailLayout2Component,
  AppPageComponent,
} from "common";
import { LoanDocumentQueryResolver } from "./document.resolver";
import { SidenavContentComponent } from "../shared/sidenav-content/sidenav-content.component";
import { TopToolbarComponent } from "../shared/top-toolbar/top-toolbar.component";
import { CustomerCurrentResolver } from "../customer/infrastructure/customer.resolver";
import { LoanResolver, LoansResolver } from "../loan/infrastructure/loan.resolver";
import { NavbarComponent } from "../shared/navbar/navbar.component";
import { BottomToolbarComponent } from "../shared/bottom-toolbar/bottom-toolbar.component";
import { OAuthGuard } from "../../guards/oauth.guard";
import { ApplicationDocumentUploadComponent } from "./document-upload/application-document-upload.component";
import {
  ApplicationResolver,
  ApplicationsResolver,
} from "../application/application.resolver";
import { AccountDocumentListComponent } from "./document-list/account-document-list.component";
import { ApplicationDocumentListComponent } from "./document-list/application-document-list.component";

const routes: Routes = [
  {
    path: "account/:accountId/documents",
    canActivate: [OAuthGuard],
    component: AppDetailLayout2Component,
    resolve: {
      customer: CustomerCurrentResolver,
      loans: LoansResolver,
      loan: LoanResolver,
    },
    children: [
      {
        path: "",
        outlet: "sidenav-content",
        component: SidenavContentComponent,
      },
      {
        path: "",
        component: TopToolbarComponent,
        outlet: "app-top-toolbar",
      },
      {
        path: "",
        component: NavbarComponent,
        outlet: "app-navbar",
      },
      {
        path: "",
        component: BottomToolbarComponent,
        outlet: "app-bottom-toolbar",
      },
      {
        path: "",
        component: AccountDocumentListComponent,
        resolve: {
          documentQueryResult: LoanDocumentQueryResolver,
          loan: LoanResolver,
        },
        runGuardsAndResolvers: "paramsOrQueryParamsChange",
      },
    ],
  },
  {
    path: "application/:appId/documents",
    canActivate: [OAuthGuard],
    component: AppDetailLayout2Component,
    resolve: {
      customer: CustomerCurrentResolver,
      applications: ApplicationsResolver,
      application: ApplicationResolver,
    },
    children: [
      {
        path: "",
        outlet: "sidenav-content",
        component: SidenavContentComponent,
      },
      {
        path: "",
        component: TopToolbarComponent,
        outlet: "app-top-toolbar",
      },
      {
        path: "",
        component: NavbarComponent,
        outlet: "app-navbar",
      },
      {
        path: "",
        component: BottomToolbarComponent,
        outlet: "app-bottom-toolbar",
      },
      {
        path: "",
        component: ApplicationDocumentListComponent,
        runGuardsAndResolvers: "paramsOrQueryParamsChange",
      },
    ],
  },
  {
    path: "application/:appId/documents/upload",
    canActivate: [OAuthGuard],
    component: AppPageComponent,
    data: {
      appBarTitle: {
        content: "Upload files",
      },
      appBar: {
        closeAction: AppBarCloseAction.BACK,
        logoHidden: true,
        stickyDisabled: true,
      },
    },
    children: [
      {
        path: "",
        component: AppBarComponent,
        outlet: "app-bar",
        children: [
          {
            path: "",
            outlet: "title",
            component: AppBarTitleComponent,
          },
          {
            path: "",
            outlet: "right",
            component: AppBarActionsComponent,
          },
        ],
      },
      {
        path: "",
        component: ApplicationDocumentUploadComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DocumentRoutingModule {}
