<div fxLayout="column"
     fxLayoutAlign="start center"
     fxLayoutGap="8px">
  <ng-container *ngIf="isNPLLoan()t">
    <div>
      Your account is frozen. Contact our Legal department at {{legalEmail}}.
    </div>
    <a mat-raised-button
       class="color-primary"
       href="mailto:{{legalEmail}}">
      <span>EMAIL US</span>
    </a>
  </ng-container>
  <ng-container *ngIf="!isNPLLoan()">
    <ifc-bar-chart *ngIf="!hasAvailableRenewalToSign && loan.status !== loanStatus.Pending && loan?.status !== loanStatus.PendingRenewal && kbaVerified"
                   [data]="barChartData"
                   [options]="barChartOptions"
                   [style.height]="height"
                   [style.width]="width"
                   [fxHide.xs]="showAvailableFunds || loan.status === loanStatus.PendingRenewal">
    </ifc-bar-chart>
    <ifp-account-status-icon-chip *ngIf="loan?.status === loanStatus.PendingRenewal"
                                  [status]="loan?.status" [loanData]="loan">
    </ifp-account-status-icon-chip>
    <div fxLayout="row"
         fxLayoutAlign="start start"
         fxLayoutGap="54px"
         fxLayoutGap.xs="24px">
      <button mat-button
              color="primary"
              (click)="pay()"
              *ngIf="payEnabled"
              [fxHide.lt-md]="showAvailableFunds || widgetLayout">
        <div fxLayout="row"
             fxLayoutAlign="start center"
             fxLayoutGap="8px">
          <span>PAY</span>
        </div>
      </button>
    </div>
  </ng-container>
</div>
