<h1 mat-dialog-title>{{data?.title}}</h1>
<mat-dialog-content class="mat-typography">
  <mat-form-field appearance="outline">
    <mat-label>Loan</mat-label>
    <mat-select matInput [(ngModel)]="selected" name="Loan">
      <mat-option *ngFor="let loan of data?.loans"   [value]="loan" >
           <span #label class="loan-description" matTooltipClass="mat-tooltip" [matTooltipDisabled]="shouldShowTooltip(label)" [matTooltip]="getLoanLabel(loan)">{{ getLoanLabel(loan) }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="undefined">Cancel</button>
  <button mat-button color="primary" (click)="save()">Ok</button>
</div>
