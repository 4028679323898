import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AppDetailLayout2Component,
  AppPageComponent,
  AppBarComponent,
  AppBarTitleComponent,
  AppFooterComponent,
  AppBarContactComponent,
  AppBarCloseAction
} from 'common';
import { CustomerCurrentResolver } from '../customer/infrastructure/customer.resolver';
import { SidenavContentComponent } from '../shared/sidenav-content/sidenav-content.component';
import { TopToolbarComponent } from '../shared/top-toolbar/top-toolbar.component';
import { BankAccountListComponent } from './bank-account-list/bank-account-list.component';
import { LoanResolver, LoansResolver } from '../loan/infrastructure/loan.resolver';
import {
  FinancialInstitutionsListResolver,
  BankActionRequiredFinancialInstitutionsResolver,
  BankAccountsForLinkReminderResolver
} from './bank-account.resolver';
import { NavbarComponent } from '../shared/navbar/navbar.component';
import { BottomToolbarComponent } from '../shared/bottom-toolbar/bottom-toolbar.component';
import { BankAccountSearchComponent } from './bank-account-search/bank-account-search.component';
import { BankAccountLinkComponent } from './bank-account-link/bank-account-link.component';
import { EntityQueryResolver } from '../entity/infrastructure/entity.resolver';
import { OAuthGuard } from '../../guards/oauth.guard';
import { ApplicationResolver, ApplicationsResolver } from '../application/application.resolver';
import { BankActionRequiredComponent } from './bank-action-required/bank-action-required.component';
import { BankAccountOauthComponent } from './bank-account-oauth/bank-account-oauth.component';

const routes: Routes = [
  {
    path: 'account/:accountId/bank-account',
    canActivate: [OAuthGuard],
    component: AppDetailLayout2Component,
    runGuardsAndResolvers: "paramsOrQueryParamsChange",
    resolve: {
      customer: CustomerCurrentResolver,
      loans: LoansResolver,
      loan: LoanResolver,
      entity: EntityQueryResolver
    },
    children: [
      {
        path: '',
        outlet: 'sidenav-content',
        component: SidenavContentComponent
      },
      {
        path: '',
        component: TopToolbarComponent,
        outlet: 'app-top-toolbar'
      },
      {
        path: '',
        component: NavbarComponent,
        outlet: 'app-navbar'
      },
      {
        path: '',
        component: BottomToolbarComponent,
        outlet: 'app-bottom-toolbar'
      },
      {
        path: '',
        component: BankAccountListComponent,
        runGuardsAndResolvers: "paramsOrQueryParamsChange",
        resolve: {
          financialInstitutions: FinancialInstitutionsListResolver,
        }
      }
    ]
  },
  {
    path: 'application/:appId/bank-account',
    canActivate: [OAuthGuard],
    component: AppDetailLayout2Component,
    runGuardsAndResolvers: "paramsOrQueryParamsChange",
    resolve: {
      customer: CustomerCurrentResolver,
      applications: ApplicationsResolver,
      application: ApplicationResolver,
      entity: EntityQueryResolver
    },
    children: [
      {
        path: '',
        outlet: 'sidenav-content',
        component: SidenavContentComponent
      },
      {
        path: '',
        component: TopToolbarComponent,
        outlet: 'app-top-toolbar'
      },
      {
        path: '',
        component: NavbarComponent,
        outlet: 'app-navbar'
      },
      {
        path: '',
        component: BottomToolbarComponent,
        outlet: 'app-bottom-toolbar'
      },
      {
        path: '',
        component: BankAccountListComponent,
        runGuardsAndResolvers: "paramsOrQueryParamsChange",
        resolve: {
          financialInstitutions: FinancialInstitutionsListResolver,
        }
      }
    ]
  },
  {
    path: 'bank-account-linking-search-institution',
    component: AppPageComponent,
    data: {
      appPage: {
        contentClass: 'simple',
        contentAlignment: 'center-start',
        contentWithoutPadding: true
      },
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        logoHidden: true,
        stickyDisabled: true
      }
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        outlet: 'app-footer',
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: false,
            hideText: true,
            showEncryptionInfo: true
          }
        }
      },
      {
        path: '',
        component: BankAccountSearchComponent,
        resolve: {
          entity: EntityQueryResolver
        }
      }
    ]
  },
  {
    path: 'bank-link-reminder',
    component: AppPageComponent,
    data: {
      appPage: {
        contentClass: 'simple',
        contentAlignment: 'center-start',
        contentWithoutPadding: true
      },
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        logoHidden: true,
        stickyDisabled: true
      }
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        outlet: 'app-footer',
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: false,
            hideText: true,
            showEncryptionInfo: true
          }
        }
      },
      {
        path: '',
        component: BankAccountLinkComponent,
        resolve: {
          entity: EntityQueryResolver,
          financialInstitutions: BankAccountsForLinkReminderResolver
        }
      }
    ]
  },
  {
    path: 'bank-account-action-required',
    component: AppPageComponent,
    data: {
      appPage: {
        contentClass: 'simple',
        contentAlignment: 'center-start',
        contentWithoutPadding: true
      },
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        logoHidden: true,
        stickyDisabled: true
      }
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        outlet: 'app-footer',
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: false,
            hideText: true,
            showEncryptionInfo: true
          }
        }
      },
      {
        path: '',
        component: BankActionRequiredComponent,
        data: { cancelUrl: AppBarCloseAction.HOME },
        resolve: {
          financialInstitutions: BankActionRequiredFinancialInstitutionsResolver
        }
      }
    ]
  },
  {
    path: 'bank-account-oauth-redirect',
    component: BankAccountOauthComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class BankAccountRoutingModule { }
