import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { BrandingService, CompanyBranding, LoanStatus } from "common";
import {
  getAccountBottomToolbarMenuItems,
  getApplicationTopToolbarMenuItems,
} from "./top-toolbar.model";
import { LoanData } from "../../loan/domain/models/loan.model";
import { ApplicationData } from "../../application/application.model";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LoanHelper } from "../../loan/infrastructure/loan.helper";

@UntilDestroy()
@Component({
  selector: "ifp-top-toolbar",
  templateUrl: "./top-toolbar.component.html",
  styleUrls: ["./top-toolbar.component.scss"],
})
export class TopToolbarComponent implements OnInit {
  toolbarMenuItems: any;
  loanStatus = LoanStatus;
  brandingData: CompanyBranding;

  constructor(
    private route: ActivatedRoute,
    private brandingService: BrandingService
  ) { }

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe(this.dataInit.bind(this));
    this.initBranding();
  }

  private initBranding() {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding) => {
        this.brandingData = branding;
      });
  }

  dataInit(data) {
    const appData: ApplicationData = data.application;
    if (appData) {
      this.toolbarMenuItems = getApplicationTopToolbarMenuItems(appData.id);
      return;
    }

    this.toolbarMenuItems = this.getAccountTopToolbarMenuItems(data.loan);
  }

  private getAccountTopToolbarMenuItems(loan: LoanData) {
    if (LoanHelper.isNPLLoan(loan.status))
      return _.filter(
        getAccountBottomToolbarMenuItems(loan.id),
        (item) => item.label != "TRANSACTIONS"
      );

    return getAccountBottomToolbarMenuItems(loan.id);
  }
}
