<div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="currentLoan && !loading">
  <div style="position: relative; z-index:999;" fxLayoutAlign="center center" fxFlex="5">
    <mat-icon *ngIf="currentLoan?.productCode === productCodes.LOC" class="mat-white">data_saver_off</mat-icon>
    <mat-icon *ngIf="currentLoan?.productCode === productCodes.Term" class="mat-white">assessment</mat-icon>
    <mat-icon *ngIf="currentLoan?.productCode === productCodes.Law" class="mat-white">work</mat-icon>
  </div>

  <div *ngIf="(!kbaVerified || hasAvailableRenewalToSign || hasCurrentLoanPending
    || currentLoan?.status === loanStatus.Pending)"
    fxLayout="column" fxLayoutAlign="center start" fxFlex="75" fxFlex.lt-md="65">
    <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="8px" *ngIf="!kbaVerified"
      class="layout margin-top margin-bottom">
      <span> You're almost there! To protect you from fraud and identity theft, we need to confirm your identity.</span>
      <button mat-button color="primary" (click)="verifyIdentity()">
        <span>VERIFY YOUR IDENTITY</span>
      </button>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="8px"
      *ngIf="hasAvailableRenewalToSign && kbaVerified && !isRenewalEligibleToAccept"
      class="layout margin-top margin-bottom">
      <span> Review and sign your {{currentLoan.pendingOffer?.amount | currency}} {{currentLoan.pendingOffer?.productName}} agreement so you can access your funds.
        See current loan details <a href="#" (click)="$event.preventDefault(); details()">here</a>.</span>
      <button mat-button color="primary" (click)="signAgreement()">
        <span>SIGN THE AGREEMENT</span>
      </button>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="8px"
    *ngIf="hasAvailableRenewalToSign && kbaVerified && isRenewalEligibleToAccept"
    class="layout margin-top margin-bottom">
    <span> Accept your {{currentLoan.pendingOffer?.amount | currency}} {{currentLoan.pendingOffer?.productName}} renewal so you can access your funds.
      See current loan details <a href="#" (click)="$event.preventDefault(); details()">here</a>.</span>
    <button mat-button color="primary" (click)="signAgreement()">
      <span>ACCEPT RENEWAL</span>
    </button>
  </div>
    <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="8px"
      *ngIf="hasCurrentLoanPending && kbaVerified"
      class="layout margin-top margin-bottom">
      <span> Review and sign your {{currentLoan.pendingOffer?.amount | currency}} {{currentLoan.pendingOffer?.productName}} agreement so you can access your funds.</span>
      <button mat-button color="primary" (click)="signAgreement()">
        <span>SIGN THE AGREEMENT</span>
      </button>
    </div>
    <div *ngIf="currentLoan?.status === loanStatus.Pending && !hasCurrentLoanPending && kbaVerified"
      class="layout margin-top margin-bottom">
      <span>Thank you! All owners included in your application must verify their identity and sign the agreement. Remind
        all owners to complete these steps.</span>
    </div>
  </div>

  <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center start" fxFlex="75" fxFlex.lt-lg="65"
    *ngIf="isNPLLoan()" class="layout margin-top margin-bottom">
    <span> Your account is frozen. Contact our Legal department at {{legalEmail}}.</span>
    <a mat-button color="primary" href="mailto:{{legalEmail}}">
      <span>EMAIL US</span>
    </a>
  </div>

  <div
    *ngIf="kbaVerified && !hasAvailableRenewalToSign && currentLoan?.status !== loanStatus.Pending && !isNPLLoan()"
    fxLayout="row" fxLayoutAlign="space-between center" fxLayout.lt-lg="column" fxLayoutGap.lt-lg="12px"
    fxLayoutAlign.lt-lg="center start" fxFlex="85" fxFlex.lt-lg="75" class="layout padding-left">
    <div fxLayout="column" fxLayoutAlign="center start" fxFlex="50" class="pointer" (click)="details()">
      <div style="display: grid;" *ngIf="currentLoan?.productCode === productCodes.Law">
        <span class="mat-subheading-2 loan-description" *ngIf="currentLoan?.productCode === productCodes.Law" #loanDescription matTooltipClass="mat-tooltip" [matTooltipDisabled]="disableMatTooltip" [matTooltip]="currentLoan?.description + ' ' + maskedLoanNumber">{{currentLoan?.description}} {{maskedLoanNumber}}</span>
      </div>
      <div *ngIf="currentLoan?.productCode !== productCodes.Law">
        <span class="mat-subheading-2" *ngIf="currentLoan?.productCode === productCodes.LOC">Line of Credit</span>
        <span class="mat-subheading-2" *ngIf="currentLoan?.productCode === productCodes.Term">Term Loan</span>
        <span class="mat-subheading-2" *ngIf="currentLoan?.productCode !== productCodes.Law"> {{maskedLoanNumber}}</span>
      </div>
      <span *ngIf="balanceExceedsTheAmountOfTheOffer; else balanceIsFine">Unfortunately your approved renewal amount of
        {{currentLoan.renewalOffer?.amount | currency:'$'}} is less than your current balance of {{balance | currency:'$'}}.
        Please pay the difference before renewing or contact your Advisor.</span>
      <ng-template #balanceIsFine>
        <span>{{status}} - {{balance | currency:'$'}} balance</span>
        <span>{{currentLoan?.loanInfo?.upcomingPayment | currency:'$'}} due on
          {{currentLoan?.loanInfo?.paymentDueDate | date: 'shortDate'}}</span>
      </ng-template>
    </div>
    <div fxFlex="50">
      <div
        *ngIf="(currentLoan?.productCode === productCodes.LOC || currentLoan?.productCode === productCodes.Law) && kbaVerified && !hasAvailableRenewalToSign && !hasCurrentLoanPending"
        fxLayout="column" fxLayoutAlign="center center">
        <ifp-loc-law-card-chart [loan]="currentLoan" [liquidityEventData]="liquidityEventData" [customer]="currentCustomer" [kbaVerified]="kbaVerified"
          [hasAvailableRenewalToSign]="hasAvailableRenewalToSign" [hasCurrentLoanPending]="hasCurrentLoanPending">
        </ifp-loc-law-card-chart>
      </div>
      <div
        *ngIf="(currentLoan?.productCode === productCodes.Term) && kbaVerified && !hasAvailableRenewalToSign && !hasCurrentLoanPending"
        fxLayout="column" fxLayoutAlign="center center">
        <ifp-term-loan-card-chart [loan]="currentLoan" [customer]="currentCustomer" [kbaVerified]="kbaVerified"
          [hasAvailableRenewalToSign]="hasAvailableRenewalToSign" [hasCurrentLoanPending]="hasCurrentLoanPending"
          [height]="'60px'" [width]="'120px'" [barColor]="barColor" [shouldDisplayLoanBarChart]="shouldDisplayLoanBarChart">
        </ifp-term-loan-card-chart>
      </div>
    </div>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="end center" fxFlex="8">
    <div fxFlexFill>
      <button *ngIf="kbaVerified && !hasAvailableRenewalToSign && currentLoan?.status !== loanStatus.Pending" mat-icon-button
        [matMenuTriggerFor]="actionsMenu" class="secondary-text more-actions-menu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
    <mat-menu #actionsMenu="matMenu">
      <button mat-menu-item (click)="details()">
        <mat-icon>description</mat-icon> <span>Details</span>
      </button>
      <button mat-menu-item (click)="draw()" *ngIf="drawEnabled && !this.hasAvailableRenewalToSign">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
          <div class="avatar medium letter mat-light-grey-bg">D</div> <span>Draw funds</span>
        </div>
      </button>
      <button mat-menu-item (click)="pay()" *ngIf="payEnabled">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
          <div class="avatar medium letter mat-light-grey-bg">P</div> <span>Pay balance</span>
        </div>
      </button>
      <ng-container *ngFor="let template of currentLoanTemplates">
        <button mat-menu-item (click)="onClickLoanTemplateOption(template)">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <mat-icon>request_quote</mat-icon> <span class="action-name">{{template.nameTemplate | sentenceCase}}</span>
          </div>
        </button>
      </ng-container>
    </mat-menu>
  </div>
</div>
