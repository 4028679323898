import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import {
  LoanStatusLabel,
  LoanStatus,
  LoanDocumentService,
  LoanFileTemplateData,
  GetFileData,
  CompanyColorSchemaEnum,
  LiquidityEvent,
  BrandingService,
  CompanyBranding,
} from "common";
import {
  LoanData,
  LoanFileTemplate,
  ProductCode,
} from "../../loan/domain/models/loan.model";
import {
  CustomerData,
  KBAVerificationStatus,
} from "../../customer/domain/models/customer.model";
import { LoanFileTemplateDialogComponent } from "../../loan/loan-file-template-dialog/loan-file-template-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { LoanFileTemplateDialogData } from "../../loan/loan-file-template-dialog/models/loan-file-template-dialog-data.model";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LoanHelper } from "../../loan/infrastructure/loan.helper";
import { LoanFacade } from "../../loan/domain/+state/loan.facade";

@UntilDestroy()
@Component({
  selector: "ifp-dashboard-account-widget",
  templateUrl: "./account-widget.component.html",
  styleUrls: ["./account-widget.component.scss"],
})
export class DashboardAccountWidgetComponent
  implements OnInit, AfterViewInit, OnChanges
{
  disableMatTooltip: boolean = true;

  @ViewChild("loanDescription", { static: false }) loanDescription: ElementRef;
  @Input() currentLoan: LoanData;
  @Input() liquidityEventData?: LiquidityEvent;
  @Input() loansTemplates: LoanFileTemplate[];
  @Input() currentCustomer: CustomerData;

  currentLoanTemplates: LoanFileTemplateData[];
  drawEnabled: boolean;
  payEnabled: boolean;
  productCodes = ProductCode;
  pendingCustomer: boolean;
  kbaVerified: boolean;
  hasCurrentLoanPending: boolean;
  hasAvailableRenewalToSign: boolean;
  isRenewalEligibleToAccept: boolean;
  showAvailableFunds: boolean;
  status: string;
  balance: number;
  loanStatus = LoanStatus;
  legalEmail: string;
  loading = true;
  balanceExceedsTheAmountOfTheOffer: boolean;
  barColor: string = "#1c9b41";
  shouldDisplayLoanBarChart: boolean;

  constructor(
    private router: Router,
    private loanFacade: LoanFacade,
    private loanDocumentService: LoanDocumentService,
    private dialog: MatDialog,
    private brandingService: BrandingService
  ) {}

  ngAfterViewInit(): void {
    this.disableMatTooltip =
      this.loanDescription?.nativeElement?.offsetWidth >=
      this.loanDescription?.nativeElement?.scrollWidth;
  }

  ngOnInit() {
    this.getBrandingInfo();
  }

  private getBrandingInfo(): void {
    this.brandingService.getBranding$()
    .pipe(untilDestroyed(this))
    .subscribe((branding: CompanyBranding) => {
      this.legalEmail = branding?.legalEmail ?? "legal@ideafinancial.com";
      if (branding.colorSchema === CompanyColorSchemaEnum.LevelEsq) {
        this.barColor = "#974142";
      }
    });
  }

  ngOnChanges(): void {
    this.initCurrentLoanTemplates();
    this.getData();
  }

  private initCurrentLoanTemplates() {
    this.currentLoanTemplates = this.loansTemplates?.find(
      (template) => template.loanId === this.currentLoan?.id
    )?.templates;
  }

  getData() {
    this.loading = true;
    this.drawEnabled = this.isDrawEnabled();
    this.payEnabled = LoanHelper.hasPayAccess(this.currentLoan);
    this.kbaVerified =
      this.currentCustomer &&
      this.currentCustomer.kbaStatus === KBAVerificationStatus.Success;

    this.pendingCustomer = LoanHelper.isLoanPendingForCustomer(
      this.currentLoan,
      this.currentCustomer
    );

    if (this.currentLoan && this.kbaVerified) {
      this.hasCurrentLoanPending = LoanHelper.isLoanPendingForSign(
        this.currentLoan,
        this.currentCustomer
      );

      this.loanFacade
        .hasLoanAvailableRenewalToSign(this.currentLoan, this.currentCustomer)
        .pipe(
          untilDestroyed(this),
          switchMap((hasAvailableRenewalToSign => {
            if(!hasAvailableRenewalToSign) {
              return of({
                hasAvailableRenewalToSign: false,
                agreementSignNeeded: false
              });
            }

            return this.isSignAgreementNeeded().pipe(
              map((agreementSignNeeded) => ({
                hasAvailableRenewalToSign,
                agreementSignNeeded,
              }))
            );
          }))
        )
        .subscribe((res) => {
          this.hasAvailableRenewalToSign =
            res.hasAvailableRenewalToSign &&
            !this.balanceExceedsTheAmountOfTheOffer;
          this.isRenewalEligibleToAccept = !res.agreementSignNeeded;
          this.loading = false;
        });
    } else this.loading = false;

    this.showAvailableFunds =
      this.currentLoan.status !== LoanStatus.Pending &&
      this.currentLoan.status !== LoanStatus.PendingRenewal &&
      !this.hasCurrentLoanPending &&
      this.kbaVerified;

    this.shouldDisplayLoanBarChart = this.currentLoan?.loanInfo?.principalBalance &&
      this.currentLoan?.loanInfo?.paymentAmount &&
      !this.currentLoan?.noAutoPayments;
    this.status = LoanStatusLabel.get(this.currentLoan.status);
    this.balance = this.getOutstandingBalance();
  }

  private isSignAgreementNeeded(): Observable<boolean> {
    return this.loanFacade.signAgreementNeeded(this.currentLoan.id).pipe(
      catchError((error) => {
        if (
          error.error.Message ===
          "The current balance exceeded the amount of the offer"
        ) {
          this.balanceExceedsTheAmountOfTheOffer = true;
        }

        return of(false);
      })
    );
  }

  private isDrawEnabled(): boolean {
    const drawEnabled = LoanHelper.hasDrawAccess(this.currentLoan);

    if (this.currentLoan?.productCode === ProductCode.Law) {
      return drawEnabled && !this.liquidityEventData?.resolutionStatus
    }
    return drawEnabled
  }

  getOutstandingBalance(): number {
    return LoanHelper.getOutstandingBalance(this.currentLoan);
  }

  draw() {
    this.router.navigate([`/account/${this.currentLoan.id}/transfer/draw`]);
  }

  pay() {
    this.router.navigate([`/account/${this.currentLoan.id}/transfer/payment`]);
  }

  details() {
    if (this.isNPLLoan()) {
      this.router.navigate([`/account/${this.currentLoan.id}/bank-account`]);
    } else {
      this.router.navigate([`/account/${this.currentLoan.id}/transactions`]);
    }
  }

  getUtilization(): number {
    return Math.round(
      (1 -
        this.currentLoan.loanInfo.availableFunds /
          this.currentLoan.loanInfo.creditLimit) *
        100
    );
  }

  verifyIdentity() {
    this.router.navigate([`account/${this.currentLoan.id}/setup`]);
  }

  signAgreement() {
    this.router.navigate([
      `account/${this.currentLoan.id}/setup/sign-agreement`,
    ]);
  }

  isNPLLoan(): boolean {
    return LoanHelper.isNPLLoan(this.currentLoan?.status);
  }

  get maskedLoanNumber(): string {
    return this.currentLoan?.loanNumber?.length > 4
      ? "*" +
          this.currentLoan.loanNumber.substr(
            this.currentLoan.loanNumber.length - 4
          )
      : null;
  }

  onClickLoanTemplateOption(loanTemplate: LoanFileTemplateData) {
    this.loanDocumentService
      .generateFileFromTemplate(this.currentLoan?.id, loanTemplate.id, true)
      .subscribe((fileData: GetFileData) => {
        const data: LoanFileTemplateDialogData = {
          loanDescription: this.currentLoan?.description,
          loanNumber: this.currentLoan?.loanNumber,
          loanTemplate,
          fileData,
        };
        LoanFileTemplateDialogComponent.show(this.dialog, data).subscribe();
      });
  }
}
