import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import * as _ from 'lodash';
import { LoanData, ProductCode } from '../../loan/domain/models/loan.model';

@Component({
  selector: 'ifp-loans-select-dialog',
  templateUrl: './loans-select-dialog.component.html',
  styleUrls: ['./loans-select-dialog.component.scss']
})
export class LoansSelectDialogComponent {

  public selected: LoanData;

  constructor(public dialogRef: MatDialogRef<LoansSelectDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  form: UntypedFormGroup = this.formBuilder.group({
    loanNumber: ''
  });

  save() {
    this.dialogRef.close(this.selected);
  }

  getLoanLabel(loan: LoanData) {
    return loan?.productCode !== ProductCode.Law ? loan?.loanNumber : loan?.description;
  }

  shouldShowTooltip(label: any) {
    return !(label?.offsetWidth > label?.offsetParent?.offsetWidth);
  }

  static show(dialog: MatDialog, title: string, loans: LoanData[]): Observable<any> {
    return dialog.open(LoansSelectDialogComponent, {
      width: '510px',
      data: {
        title: title,
        loans: loans
      },
      closeOnNavigation: true
    }).afterClosed();
  }

}
