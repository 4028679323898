import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BrandingService, ObjectHelper } from 'common';
import { AppSettings } from 'projects/portal/src/app.settings';
import { OidcService } from '../oidc/oidc.service';
import { HttpParams } from '@angular/common/http';
import { untilDestroyed } from '@ngneat/until-destroy';

@Injectable({
  providedIn: 'root'
})
export class UserSignUpGuard implements CanActivate {

  constructor(
    private appSettings: AppSettings,
    private oidcService: OidcService,
    private brandingService: BrandingService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const queryParamsString = ObjectHelper.getQueryParams(route.queryParams);
    const authReqestQueryParams = this.oidcService.getHttpRequestParams();
    let queryParams = null;

    if (queryParamsString) {
      queryParams = new HttpParams({ fromString: queryParamsString });
      if (authReqestQueryParams.get('email') === queryParams.get('email')) {
        queryParams = queryParams.delete('email');
      }
    }

    this.brandingService.getBranding().pipe(untilDestroyed(this)).subscribe(branding => {
      window.location.href = branding?.loginUrl + this.appSettings.auth.register + '?' + authReqestQueryParams.toString() + (queryParams ? `&${queryParams.toString()}` : '');
    })
    return true;
  }
}
