import {
  Component,
  Input,
  SimpleChanges,
  OnChanges,
  OnInit,
} from "@angular/core";
import { LoanData } from "../../loan/domain/models/loan.model";
import { Chart, ChartConfiguration } from "chart.js";
import { Router } from "@angular/router";
import { CustomerData } from "../../customer/domain/models/customer.model";
import { LoanStatus, LoanInfoDto, CompanyBranding, BrandingService } from "common";
import {
  getBarChartData,
  getBarChartOptions,
} from "./term-loan-bar-chart-data";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LoanHelper } from "../../loan/infrastructure/loan.helper";

@UntilDestroy()
@Component({
  selector: "ifp-term-loan-card-chart",
  templateUrl: "./term-loan-card-chart.component.html",
  styleUrls: ["./term-loan-card-chart.component.scss"],
})
export class TermLoanChartContentComponent implements OnInit, OnChanges {
  @Input() loan: LoanData;
  @Input() customer: CustomerData;
  @Input() showAvailableFunds: boolean;
  @Input() widgetLayout: boolean;
  @Input() width: string;
  @Input() height: string;
  @Input() kbaVerified: boolean;
  @Input() hasAvailableRenewalToSign: boolean;
  @Input() hasCurrentLoanPending: boolean;
  @Input() barColor: string;
  @Input() shouldDisplayLoanBarChart: boolean;

  dataTermPrincipalBalance: number[] = [];
  dataTermInterestBalance: number[] = [];
  labelTerm: string[] = [];
  colors: string[] = [];
  loanStatus = LoanStatus;
  payEnabled: boolean;
  pendingCustomer: boolean;
  displayYAxes: boolean = false;
  legalEmail: string;

  barChartData: ChartConfiguration<"bar">["data"] = null;
  barChartOptions: ChartConfiguration<"bar">["options"] = null;

  constructor(
    private router: Router,
    private brandingService: BrandingService
  ) {}

  ngOnInit(): void {
    this.initBrandingInfo();
  }

  private initBrandingInfo(): void {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding: CompanyBranding) => {
        this.legalEmail = branding?.legalEmail ?? "legal@ideafinancial.com";
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.loan && this.loan.loanInfo) {
      this.payEnabled = LoanHelper.hasPayAccess(this.loan);
      if (this.shouldDisplayLoanBarChart) {
        this.labelTerm = [];
        this.colors = [];
        this.dataTermInterestBalance = [];
        this.dataTermPrincipalBalance = [];
        Chart.defaults.color = "#1c9b41";
        this.pushDataToLoanBarChart(this.loan.loanInfo);
      }
    }
    this.chartInit();
  }

  private pushDataToLoanBarChart(data: LoanInfoDto) {
    const totalInterest = Math.round(
      (data?.amortizedBalance || data?.creditLimit) *
      data.interestRate *
      100.0
    ) / 100.0;
    const totalNop = data.totalNumberOfPayments;
    const denominator = (totalNop / 2) * (totalNop + 1);
    const termPayment = data.paymentAmount;

    let interestBalance = totalInterest;
    let principalBalance = data?.amortizedBalance || data?.creditLimit;

    for (let idx = 0; idx < totalNop; idx++) {
      const interestPayment = Math.round(
        ((totalInterest * (totalNop - idx)) / denominator) * 100.0
      ) / 100.0;
      const principalPayment = termPayment - interestPayment;

      interestBalance -= interestPayment;
      principalBalance -= principalPayment;

      this.labelTerm.push("Balance");
      this.colors.push(
        idx === totalNop - data.numberOfPaymentsLeft
          ? "#1c9b41"
          : "rgba(255, 255, 255, 0.87)"
      );
      this.dataTermInterestBalance.push(interestBalance);
      this.dataTermPrincipalBalance.push(principalBalance);
    }
  }

  private chartInit() {
    this.barChartData = getBarChartData(
      this.dataTermPrincipalBalance,
      this.labelTerm,
      this.barColor
    );
    this.barChartOptions = getBarChartOptions(this.displayYAxes);
  }

  pay() {
    this.router.navigate([`/account/${this.loan.id}/transfer/payment`]);
  }

  verifyIdentity() {
    this.router.navigate([`account/${this.loan.id}/setup`]);
  }

  signAgreement() {
    this.router.navigate([`account/${this.loan.id}/setup/sign-agreement`]);
  }

  isNPLLoan(): boolean {
    return LoanHelper.isNPLLoan(this.loan?.status);
  }
}
